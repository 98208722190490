<template>
  <a-modal
    title="商品规格属性新增"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <a-form-item label="规格属性" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-select
                style="width: 100%; min-width: 250px"
                placeholder="请选择规格属性"
                v-decorator="['specValueId', { rules: [{ required: true, message: '请选择规格属性' }] }]"
              >
                <a-select-option v-for="(item, index) in specList" :key="index" :value="item.id">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import {  bindCommoditySpecValue } from '@/api/modular/mallLiving/commodity/commodityList'
import { specValueList } from '@/api/modular/mallLiving/specValue'

export default {
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      orgTree: [],
      orgList: [],
      posList: [],
      sysEmpParamExtList: [],
      memberLoading: false,
      form: this.$form.createForm(this),
      data: [],
      birthdayString: [],
      specList: [],
      specId: '',
      goodsId: '',
    }
  },
  methods: {
    // 初始化方法
    add(record, goods) {
      console.log(record)
      this.specId = record.goodsSpecId
      this.goodsId = goods.id
      this.visible = true
      this.findAllSpecvalueList(record.specId)
    },

    //查询所有规格
    async findAllSpecvalueList(specId) {
      console.log(specId)
      let data = await specValueList({ specId: specId })
      if (data.code == 200) {
        this.specList = data.data
      }
    },

    handleSubmit() {
        const {
            form: { validateFields },
        } = this
        let that= this;
        validateFields((errors, values) => {
            if (!errors) {
                that.confirmLoading = true
                var params = {
                    goodsSpecId: this.specId,
                    goodsId: this.goodsId,
                    specValueId: values.specValueId,
                }
                bindCommoditySpecValue(params).then((res) => {
                    if (res.success) {
                        that.$message.success('商品规格值绑定成功')
                        that.confirmLoading = false
                        that.visible = false
                        that.$emit('ok', values)
                        that.handleCancel()
                    }
                }).finally((err) => {
                this.confirmLoading = false
                })
            } else {
                this.confirmLoading = false
            }
        })
    },
    handleCancel() {
      this.form.resetFields()
      this.visible = false
      // 清理子表单中数据
      this.data = []
      // 清理时间
      this.birthdayString = []
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>