import { axios } from '@/utils/request'
/*
 * @Description:
 * @Version:
 * @Author: 彭勃
 * @Date: 2022-01-21
 */
//平台下架商品
export function platformLowerShelfGoods(parameter) {
  return axios({
    url: '/goods/platformLowerShelfGoods',
    method: 'post',
    data: parameter,
  })
}
//商品列表分页
export function getCommodityList(parameter) {
  return axios({
    url: '/goods/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}
//商品列表详情
export function commodityDetail(parameter) {
  return axios({
    url: '/goods/detail',
    method: 'post',
    params: parameter,
  })
}
//商品列表新增
export function commodityAdd(parameter) {
  return axios({
    url: '/goods/add',
    method: 'post',
    data: parameter,
  })
}
//商品分类编辑
export function commodityEdit(parameter) {
  return axios({
    url: '/goods/edit',
    method: 'post',
    data: parameter,
  })
}
//商品列表删除/显示/隐藏
export function commodityDelete(parameter) {
  return axios({
    url: '/goods/delete',
    method: 'post',
    data: parameter,
  })
}

//绑定商品规格名
export function bindCommoditySpec(parameter) {
  return axios({
    url: '/goods/bindSpec',
    method: 'post',
    data: parameter,
  })
}

//删除商品绑定的规格名
export function deleteCommoditySpec(parameter) {
  return axios({
    url: '/goods/deleteGoodsSpec',
    method: 'post',
    params: parameter,
  })
}

//设置价格（绑定规格值）
export function bindCommoditySpecValue(parameter) {
  return axios({
    url: '/goods/bindSpecValue',
    method: 'post',
    data: parameter,
  })
}

//查询已绑定的规格值列表
export function querySpecValueListBySpcId(parameter) {
  return axios({
    url: '/goodsSpecValue/list',
    method: 'get',
    params: parameter,
  })
}

//查询已绑定的规格名列表
export function querySpecListByCommodityId(parameter) {
  return axios({
    url: '/goodsSpec/list',
    method: 'get',
    params: parameter,
  })
}

//删除商品和规格值的关联
export function deleteGoodsSpecValue(parameter) {
  return axios({
    url: '/goods/deleteGoodsSpecValue',
    method: 'post',
    params: parameter,
  })
}

//审核商家上架的商品
export function goodsCheckResult(parameter) {
  return axios({
    url: '/goods/checkResult',
    method: 'post',
    data: parameter,
  })
}

//删除商品和规格名的关联
export function deleteGoodsSpec(parameter) {
  return axios({
    url: '/goods/deleteGoodsSpec',
    method: 'post',
    params: parameter,
  })
}

//查询商品的规格名和规格值组合列表信息
export function getSkuListByGoodsId(paramter) {
  return axios({
    url: '/sku/goodsSpecAndSpecValue',
    method: 'post',
    params: paramter,
  })
}

//设置价格（生成sku)
export function addSkuData(paramter) {
  return axios({
    url: '/sku/createSku',
    method: 'post',
    data: paramter,
  })
}

//获取视频的封面图
export function getVideoCoverImage(paramter) {
  return axios({
    url: '/api/video/getCoverImage',
    method: 'post',
    params: paramter,
  })
}

//推荐商品列表分页
export function recommendGoodPage(parameter) {
  return axios({
    url: '/recommendGoods/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}

//添加推荐商品
export function addRecommendGoods(data) {
  return axios({
    url: '/recommendGoods/add',
    method: 'post',
    data,
  })
}
//添加推荐商品
export function editRecommendGoods(data) {
  return axios({
    url: '/recommendGoods/edit',
    method: 'post',
    data,
  })
}
//删除推荐商品
export function deleteRecommendGoods(data) {
  return axios({
    url: '/recommendGoods/delete',
    method: 'post',
    data,
  })
}


/**
 * 批量改价
 */


//分页查询商品规格列表
export function goodsSpecPage({page,queryParam}) {
  return axios({
    url: '/goodsSpec/goodsSpecPage',
    method: 'post',
    params:page,
    data:queryParam,
  })
}


//批量改价
export function updatePriceBatch(data) {
  return axios({
    url: '/goodsSpec/updatePriceBatch',
    method: 'post',
    data:data,
  })
}

//将某规格的价格等其他信息同步到所有区域
export function updatePriceToArea(data) {
  return axios({
    url: '/goodsSpec/updatePriceToArea',
    method: 'post',
    data:data,
  })
}


// 批量上下架/售罄
export function updateInfoBatch(data) {
  return axios({
    url: '/goods/updateInfoBatch',
    method: 'post',
    data:data,
  })
}
//复制某区域平台商品到其他区域
export function copyGoodsToArea(data) {
  return axios({
    url: '/goods/copyGoodsToArea',
    method: 'post',
    data:data,
  })
}