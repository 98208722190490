//多规格值接口
import { axios } from '@/utils/request'

// 多规格值分页查询
export function specValuePageList(parameter){
    return axios({
        url: '/specValue/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//多规格值查询所有
export function specValueList(parameter){
    return axios({
        url: '/specValue/list',
        method: 'post',
        params: parameter
    })
}
//多规格值详情
export function specValueDetail(parameter){
    return axios({
        url: '/specValue/detail',
        method: 'post',
        params: parameter 
    })
}
//多规格值增加
export function specValueAdd(parameter){
    return axios({
        url: '/specValue/add',
        method: 'post',
        data: parameter
    })
}
//多规格值删除
export function specValueDelete(parameter){
    return axios({
        url: '/specValue/delete',
        method: 'post',
        data: parameter
    })
}
//多规格值编辑
export function specValueEdit(parameter){
    return axios({
        url: '/specValue/edit',
        method: 'post',
        data: parameter
    })
}